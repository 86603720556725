import React, { useEffect, useState } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
//components
import Lateral from './Lateral';
import HeaderDash from './HeaderDash';
import Settings from './comp-settings/Settings';
import MyThemplate from './comp-template/MyThemplate';
import Product from './comp-product/Product';
import { useDispatch, useSelector } from 'react-redux';
import { getInfoCollection } from '../../../firebase/credentials';
import { setAllAgenda, setOrderList, setUpdateUserData } from '../../../redux/reducer/admin';
import { setInfoMenu } from '../../../redux/reducer/menu';
import Advertisement from './Advertisement';
import ModalCropImage from './comp-settings/ModalCropImage';
import Customer from './comp-contactlist/Customer';
import KitchenHome from './comp-kitchen/KitchenHome';
import Notification from './Notification';
import LoadingForm from './comp-settings/LoadingForm';
import ModalCropProduct from './comp-settings/ModalCropProduct';
import ModalViewProduct from './comp-product/create/ModalViewProduct';
import ModalViewCatalog from './comp-product/catalog/ModalViewCatalog';
import ModalDeleteProduct from './comp-product/catalog/ModalDeleteProduct';
import Design from './comp-design/Design';
import State from './comp-design/State';
import CropImages from './comp-design/CropImages';
import { setImgPostLogo } from '../../../redux/reducer/post';
import getOrderInfo from '../../../firebase/read/getOrderInfo';
import OrderInMenu from './comp-order-in-menu/OrderInMenu';
import Novelties from './comp-novelties/Novelties';
import Qr from './comp-qr/Qr';
import LoadAdmin from './LoadAdmin';
import ChangePassword from './change-password/ChangePassword';
import HomeReports from './comp-reports/HomeReports';
import ModalUpdatePlan from './comp-novelties/ModalUpdatePlan';
import CloseAdvertisement from './CloseAdvertisement'
import PaymentModal from './PaymentModal'
import WelcomeVideo from './welcome-video/WelcomeVideo';
import TutorialModal from './TutorialModal';
import Payments from './comp-payments/Payments';

const Dashboard = () => {
  const { userID, credits, phone, cuota, indicative, step } = useSelector((state) => state.log);
  const { seo, activeMenu } = useSelector((state) => state.menu);
  const dispatch = useDispatch();
  const [isReady, setIsReady] = useState(0);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 13) {
        event.preventDefault();
      }
    };
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  useEffect(() => {
    const unsubscribe = getInfoCollection(userID, process.env.REACT_APP_DATAUSER, (data) => {
      console.log('llamando info usuario');
      dispatch(setUpdateUserData(data));
      dispatch(setImgPostLogo(data.i));
      setIsReady((prev) => prev + 1);
    });

    return () => unsubscribe();
  }, [userID, dispatch]);

  useEffect(() => {
    const unsubscribe2 = getInfoCollection(userID, process.env.REACT_APP_DATAMENU, (data) => {
      console.log('llamando info digitalmenu');
      dispatch(setInfoMenu(data));
      setIsReady((prev) => prev + 1);
    });

    return () => unsubscribe2();
  }, [userID, dispatch]);

  useEffect(() => {
    const unsubscribe3 = getInfoCollection(userID, process.env.REACT_APP_AGENDA, (data) => {
      console.log('llamando info agenda');
      dispatch(setAllAgenda(data.n));
      setIsReady((prev) => prev + 1);
    });

    return () => unsubscribe3();
  }, [userID, dispatch]);

  useEffect(() => {
    if (phone && indicative) {
      getOrderInfo(`${indicative}${phone}`, (data) => {
        if (data === null) {
          console.log('error');
        } else {
          dispatch(setOrderList(data));
          setIsReady((prev) => prev + 1);
        }
      });
    }
  }, [phone, dispatch, indicative]);

  const contentList = [
    { component: <Novelties userID={userID} />, class: 'novedades', active: true, show: true},
    { component: <Settings userID={userID} />, class: 'configuracion', active: false, show: credits < 1 ? false : true },
    { component: <Product userID={userID} />, class: 'productos', active: false, show: credits < 1 ? false : true },
    { component: <MyThemplate userID={userID} />, class: 'plantilla', active: false, show: credits < 1 ? false : true },
    { component: <HomeReports userID={userID} />, class: 'reportes', active: false, show: credits < 1 ? false : true },
    { component: <Qr userID={userID} />, class: 'qr', active: false, show: credits < 1 ? false : true },
    { component: <Design userID={userID} />, class: 'post', active: false, show: credits < 1 ? false : true },
    { component: <State userID={userID} />, class: 'state', active: false, show: credits < 1 ? false : true },
    { component: <KitchenHome userID={userID} />, class: 'cocina', active: false, show: credits < 1 ? false : true },
    { component: <Customer userID={userID} />, class: 'libreta', active: false, show: credits < 1 ? false : true },
    { component: <Payments userID={userID} />, class: 'payments', active: false, show: credits < 1 ? false : true },
  ]



  return (
    <HelmetProvider>
      {isReady >= 4 ? (
        <>
          <Helmet>
            <title>Administrador - Fooder</title>
            <link rel="icon" href={seo.a ? seo.a : null} />
            <script src="https://checkout.bold.co/library/boldPaymentButton.js"></script>
          </Helmet>
          <div className={`dashpage ${cuota === 2 ? 'corp' : ''} ${cuota === 3 ? 'premium' : ''}`}>
            <main>
              <Lateral />
            </main>
            <aside>
              <HeaderDash />
              {activeMenu === false && <CloseAdvertisement />}
              {credits <= 30 && <Advertisement credits={credits} />}
              <div className="tab-content" id="contentDashboardTabs">
                {contentList.map((el, index) => {
                  if(el.show){
                    return(
                      <div
                        key={`${index}-tab`}
                        className={`tab-pane fade ${el.active ? 'active show' : ''}`}
                        id={`${el.class}-tab-pane`}
                        role="tabpanel"
                        aria-labelledby={`${el.class}-tab`}
                        tabIndex="0"
                      >
                        {el.component}
                      </div>
                    )
                  }
                })}
              </div>
            </aside>
          </div>
          <Notification />
          <ModalCropImage />
          <ModalCropProduct />
          <ModalViewProduct />
          <ModalViewCatalog />
          <ModalDeleteProduct />
          <CropImages />
          <OrderInMenu />
          <ChangePassword />
          <LoadingForm />
          <ModalUpdatePlan />
          <PaymentModal />
          {step === 1 && <WelcomeVideo />}
          <TutorialModal />
        </>
      ) : (
        <LoadAdmin />
      )}
    </HelmetProvider>
  );
};

export default Dashboard;

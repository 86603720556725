import React, { useState } from 'react'
import InfoGreen from '../../InfoGreen'

const KeysForm = ({image, title, switchText, description,production,testing,checkboxId, handleActive}) => {

  const [hide1, setHidde1] = useState(true)
  const [hide2, setHidde2] = useState(true)
  const [hide3, setHidde3] = useState(true)
  const [hide4, setHidde4] = useState(true)
  const [testMode, setTestMode] = useState(true)

  const handleMode = () => setTestMode(!testMode)

  const handleSend = ()=>{

  }

  return (
    <form>
      <div className="row">

        <div className="col-12 mb-3">
          <div className="row justify-content-center">
            <div className="col-md-10 align-self-center">
              <div className='d-flex align-self-center mb-4 mb-md-0'>
                <div className="toggle-border mb-0 me-3">
                  <input
                    id={checkboxId}
                    type="checkbox"
                    /* defaultChecked={paymentAccount.a} */
                    onChange={handleActive}
                  />
                  <label htmlFor={checkboxId}>
                    <div className="handle"></div>
                  </label>
                </div>
                <span className='text-white d-flex align-items-center'>Activar pagos en {switchText}</span>
              </div>
            </div>
            <div className="col-md-2 col-6 align-items-center">
              <img src={image} alt="" className='img-fluid'/>
            </div>
          </div>
        </div>
        <div className="col-12">
          <hr className='mt-0'/>
        </div>

        <div className="col-md-12  align-self-center">
          <h5 className='text-apple mb-3 text-center'>{title}</h5>
          <p>{description}</p>
        </div>
        <div className="col-md-12">
          <h6 className='text-apple mb-4'>{production.title}</h6>
          <div className="bg-white p-3 rounded-3 text-dark">
            <div>
              <h6 className='mb-2'>{production.label1}</h6>
              <p>{production.description1}</p>
              <div className="inputPass">
                <input 
                  autoComplete="off"
                  type={hide1 ? 'password': 'text'}
                />
                <button type='button' className={hide1 ? 'hide': ''} onClick={()=> setHidde1(!hide1)}/>
              </div>
            </div>
            <div>
              <h6 className='mb-2'>{production.label2}</h6>
              <p>{production.description2}</p>
              <div className="inputPass">
                <input 
                  autoComplete="off"
                  type={hide2 ? 'password': 'text'}
                />
                <button type='button' className={hide2 ? 'hide': ''} onClick={()=> setHidde2(!hide2)}/>
              </div>
            </div>
          </div>
        </div>


        <div className="col-md-12">
          <h6 className='text-apple my-4'>{testing.title}</h6>
          <div className="bg-white p-3 rounded-3 text-dark">
            <div>
              <h6 className='mb-2'>{testing.label1}</h6>
              <p>{testing.description1}</p>
              <div className="inputPass">
                <input 
                  autoComplete="off"
                  type={hide3 ? 'password': 'text'}
                />
                <button type='button' className={hide3 ? 'hide': ''} onClick={()=> setHidde3(!hide3)}/>
              </div>
            </div>
            <div>
              <h6 className='mb-2'>{testing.label2}</h6>
              <p>{testing.description2}</p>
              <div className="inputPass">
                <input 
                  autoComplete="off"
                  type={hide4 ? 'password': 'text'}
                />
                <button type='button' className={hide4 ? 'hide': ''} onClick={()=> setHidde4(!hide4)}/>
              </div>
            </div>
          </div>
        </div>
        <div className="col-12">
          <h6 className='my-4 text-center text-apple'>Estás en modo de {testMode? 'Pruebas': 'Producción'}</h6>
          <div className="d-flex justify-content-center my-4">
            <div className="toggle-border">
              <input
                id="one"
                type="checkbox"
                onChange={handleMode}
              />
              <label htmlFor="one">
                <div className="handle"></div>
              </label>
            </div>
          </div>
        </div>

        {
          testMode ? (
            <InfoGreen
              text='Ahora puedes simular los pagos desde tu menú digital. NO RECIBIRÁS PAGOS REALES, sin embargo, puedes verificar desde tu menú digital si las llaves que ingresaste funcionan correctamente. No olvides preguntar a tu proveedor de pagos cuáles son los datos FAKE para simular todo el proceso de pagos.'
            />
          ):
          (
            <InfoGreen
              text={`¡Genial! Ahora puedes recibir tu dinero desde tu proveedor de pagos ${switchText}. Verifica que el botón de pago en tu menú se encuentre disponible y no genere errores.`}
            />
          )
        }
        <div className="col-12 mt-4 mb-3">
          <div className="row justify-content-center">
            <div className="col-md-4">
              <button
                className='btnSave w-100'
                type='button'
                onClick={handleSend}
              >Guardar cambios</button>
            </div>
          </div>
        </div>
        
      </div>
    </form>
  )
}

export default KeysForm
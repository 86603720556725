import React, { useRef, useState } from 'react'
import * as XLSX from 'xlsx'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import uniqid from 'uniqid'
import { setFilterClient, setIDClientToDelete, setIsError, setStateLoading, setStateNotification, setTextNotificate } from '../../../../redux/reducer/interactions'
import LoadingForm from '../comp-settings/LoadingForm'
import EmptyClient from './EmptyClient'
import ModalDeleteClient from './ModalDeleteClient'
import Slider from 'react-slick'
import { setDataClient } from '../../../../redux/reducer/ordering'
import ModalCreateOrder from './ModalCreateOrder'
import TableList from './TableList'
import updateOnlyOnePiece from '../../../../firebase/update/updateOnlyOnePiece'
import InfoGreen from '../InfoGreen'


const Customer = () => {

  const {customerList,customerListLimit, userID} = useSelector(state => state.log)
  const {activeMenu} = useSelector(state => state.menu)
  const {stateLoading, filterClient} = useSelector(state => state.ux)
  const dispatch = useDispatch()
  const [isEdit, setIsEdit] = useState(false)
  const [idNameSelected, setIdNameSelected] = useState(null)
  const [addNew, setAddNew] = useState(false)
  const slideClient = useRef(null)
  const countPagination = 100

  const handleNewCustomer = ()=>{
    setAddNew(!addNew)
  }

 
  const {register, handleSubmit, formState: { errors },reset, setValue } = useForm({
    mode: 'onChange'
  })


  const submitData = async(data)=>{

    const {caddress,cname,cneightborhood,ctel} = data
    dispatch(setStateLoading(true))
    let copy = JSON.parse(JSON.stringify(customerList))

    let newContact = {
      id: uniqid(),
      name: cname,
      tel: ctel,
      address: caddress,
      neighborhood: cneightborhood
    }

    if(isEdit){
      const ref = customerList?.findIndex(el => el?.id === idNameSelected)
      if(ref !== -1){
        newContact.id = idNameSelected
        copy[ref] = newContact
        try {
          await updateOnlyOnePiece(userID, process.env.REACT_APP_AGENDA,'n', copy)
          dispatch(setTextNotificate('Los datos de tu cliente han sido editados'))
          dispatch(setStateNotification(true))
          dispatch(setIsError(false))
          dispatch(setStateLoading(false))
          reset()
          cleanEdition()
        } catch (error) {
          dispatch(setTextNotificate('Ha ocurrido un error al editar tu cliente, inténtalo nuevamente'))
          dispatch(setStateNotification(true))
          dispatch(setIsError(true))
          dispatch(setStateLoading(false))
          console.log(error)
        }
      }else{
        dispatch(setTextNotificate('El nombre no fué encontrado en tu lista para ser remplazado'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(true))
        dispatch(setStateLoading(false))
        cleanEdition()
      }
    }else{
      copy.unshift(newContact)
      try {
        await updateOnlyOnePiece(userID, process.env.REACT_APP_AGENDA,'n', copy)
        dispatch(setTextNotificate(`¡Tu nuevo contacto ${cname} ha sido guardado como cliente!`))
        dispatch(setStateNotification(true))
        dispatch(setIsError(false))
        dispatch(setStateLoading(false))
        reset()
        setAddNew(false)
      } catch (error) {
        dispatch(setTextNotificate('Ha ocurrido un error, inténtalo nuevamente'))
        dispatch(setStateNotification(true))
        dispatch(setIsError(true))
        dispatch(setStateLoading(false))
        console.log(error)
      }
    }
    
  }

  const onEdit = (id, name, phone, address, neighborhood) =>{
    setIsEdit(true)
    setAddNew(true)
    setValue('cname',name)
    setValue('ctel',phone)
    setValue('caddress',address)
    setValue('cneightborhood',neighborhood)
    setIdNameSelected(id)
  }

  const onDelete = (name, index) =>{
    dispatch(setIDClientToDelete({name, index}))
  }

  const cleanEdition = ()=>{
    reset()
    setIsEdit(false)
    setAddNew(false)
    setIdNameSelected(null)
  }

  const handleFilter = (e)=>{
    dispatch(setFilterClient(e.target.value))
  }

  const handleCleanFilter = ()=>{
    dispatch(setFilterClient(''))
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'slideClient'
  }

  const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };

  
  const chunkedCustomerList = chunkArray(customerList, countPagination)
  const matchingClient = customerList?.filter(el => el?.name?.toLowerCase().includes(filterClient?.toLowerCase()) ||  el?.tel?.toLowerCase().includes(filterClient?.toLowerCase()))

  const slideNext = ()=>{
    slideClient.current.slickNext()
  }
  const slidePrev = ()=>{
    slideClient.current.slickPrev()
  }

  const downloadExcel = () => {
    
    const reorderedData = customerList.map(customer => {
      const { id, name, tel, address, neighborhood } = customer;
      return {
        id, name, phone: tel, address, neighborhood
      };
    });

    const worksheet = XLSX.utils.json_to_sheet(reorderedData);
    
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Contacts');

    XLSX.writeFile(workbook, 'lista_de_contactos.xlsx');
  };

  const newOrder = el =>{
    dispatch(setDataClient(el))
  }

  const handleCreateNewClient = () =>{
    setAddNew(true)
    setValue('ctel',filterClient)
    dispatch(setFilterClient(''))
  }

 
  return (
    <>
      {
        activeMenu === true ? (
          <>
            <section className="py-3 py-md-4 px-0 px-md-3 customerListContent ">
              <div className="container-fluid mb-5 mb-md-0">
                <div className="row justify-content-center mb-5 mb-md-0">
                  <div className="col-md-9 align-self-center">
                    <div className="">
                      <h3 className="text-apple">Lista de clientes</h3>
                      <p className='text-white mb-4'>{`${customerList?.length} de ${customerListLimit || null}`} Contactos posibles</p>
                    </div>
                  </div>
                  <div className="col-md-3">
                    {
                      isEdit ? (
                        <button
                          className="btn-line-apple"
                          onClick={cleanEdition}
                        >Cancelar edición</button>
                      ):
                      (
                        <button
                          className="btn-line-apple addClient my-3 mt-md-0"
                          onClick={handleNewCustomer}
                        >{addNew ? 'Cancelar': 'Agregar cliente'}</button>
                      )
                    }
                  </div>
                  {
                    addNew && (
                      <form onSubmit={handleSubmit(submitData)}>
                        <div className="col-md-12 mb-3">
                          <div className="bg-b1 rounded-lg px-2 px-md-4 py-4">
                            <div className="row justify-content-center">
                              <div className="col-12 mb-3">
                                <h5 className='text-apple text-center text-md-start mb-0 mb-md-3'>{isEdit? 'Editando datos del cliente': 'Agregar cliente'}</h5>
                              </div>
                              <div className="col-md-6">
                                <label className='dashLabel mb-2 ps-2'>Nombre completo <span>*</span></label>
                                <div className="inputDash ">
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    className='bg-b2 rounded px-3'
                                    {...register('cname',{
                                      required: {value: true, message: 'Nombre requerido'},
                                      minLength: {value: 2, message: 'Revisa el nombre'},
                                      maxLength: {value: 50, message: 'El nombre debe tener como máximo 50 caracteres'},
                                    })}
                                  />
                                </div>
                                {errors.cname && <small className='errorDash'>{errors.cname.message}</small>}
                              </div>
                              <div className="col-md-6">
                                <label className='dashLabel mb-2 ps-2'>Número de celular <span>*</span></label>
                                <div className="inputDash ">
                                  <input
                                    autoComplete="off"
                                    type="tel"
                                    className='bg-b2 rounded px-3'
                                    {...register('ctel',{
                                      required: {value: true, message: 'Número de contacto requerido'},
                                      pattern: {value: /^\d{10}$/, message: 'El número debe ser de 10 dígitos y sin espacios'}
                                    })}
                                    
                                  />
                                </div>
                                {errors.ctel && <small className='errorDash'>{errors.ctel.message}</small>}
                              </div>
                              <div className="col-md-6">
                                <label className='dashLabel mb-2 ps-2'>Dirección <span>*</span></label>
                                <div className="inputDash">
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    className='bg-b2 rounded px-3'
                                    {...register('caddress',{
                                      required: {value: true, message: 'Dirección requerida'},
                                      minLength: {value: 2, message: 'Revisa la dirección'},
                                      maxLength: {value: 50, message: 'Longitud máxima para la dirección'},
                                    })}
                                  />
                                </div>
                                {errors.caddress && <small className='errorDash'>{errors.caddress.message}</small>}
                              </div>
                              <div className="col-md-6">
                                <label className='dashLabel mb-2 ps-2'>Barrio</label>
                                <div className="inputDash">
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    placeholder='Barrio'
                                    className='bg-b2 rounded px-3'
                                    {...register('cneightborhood',{
                                      required: false,
                                      maxLength: {value: 50, message: 'Longitud máxima para el barrio'},
                                    })}
                                  />
                                </div>
                                {errors.cneightborhood && <small className='errorDash'>{errors.cneightborhood.message}</small>}
                              </div>
                              <div className="col-12 mt-3">
                                <div className="row justify-content-center">
                                  <div className="col-md-3">
                                    <button
                                      type='submit'
                                      className="btn-save-dash w-100"
                                    >
                                      {isEdit? 'Guardar edición': 'Guardar cliente'}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              
                            </div>
                          </div>
                        </div>
                      </form>
                    )
                  }

                  {
                    customerList.length === 0 ?
                      <EmptyClient />
                      :
                      (
                        <div className='row'>
                          <div className="bg-b1 rounded p-3">
                            <div className="row">
                              <div className="col-md-5">
                                <div className="inputDash m-0 bg-b2">
                                  <input
                                    autoComplete="off"
                                    type="text"
                                    placeholder='Buscar cliente'
                                    className='bg-b2 rounded px-3'
                                    value={filterClient}
                                    onChange={e => handleFilter(e)}
                                  />
                                </div>
                              </div>
                              <div className="col-md-7 align-self-center">
                                {
                                  filterClient?.length === 0 ? (
                                    <div className='row mt-3 mt-md-0'>    
                                      <div className="col-md-6">
                                        <button
                                          className='btn-line-apple excel'
                                          onClick={downloadExcel}
                                        >Descargar agenda</button>
                                      </div>
                                      <div className="col-md-3 col-6 mt-4 mt-md-0">
                                        <button
                                          className={`btn-line-apple ${customerList.length > countPagination ? '': 'disabled'}`}
                                          onClick={slidePrev}
                                        >{`<Anterior`}</button>
                                      </div>
                                      <div className="col-md-3 col-6 mt-4 mt-md-0">
                                        <button
                                          className={`btn-line-apple ${customerList.length > countPagination ? '': 'disabled'}`}
                                          onClick={slideNext}
                                        >{`Siguiente >`}</button>
                                      </div>   
                                    </div>
                                  ):
                                  (
                                    <div className='row'>
                                      <div className="col-md-5">
                                        <button
                                          className="btn-line-apple"
                                          onClick={handleCleanFilter}
                                        >Limpiar búsqueda</button>
                                      </div>
                                    </div>
                                  )
                                }
                              </div>
                            </div>
                          </div>
                          <div className="bg-b1 p-3 rounded my-3 text-apple d-none d-md-block">
                            <div className="row text-center">
                              <div className="col-md-3"><span>Nombre completo</span></div>
                              <div className="col-md-2"><span>Celular</span></div>
                              <div className="col-md-3"><span>Dirección</span></div>
                              <div className="col-md-2"><span>Barrio</span></div>
                              <div className="col-md-2"><span>Acciones</span></div>
                            </div>
                          </div>
                          {
                            filterClient?.length === 0 ? (
                              <Slider {...settings} ref={slideClient}>
                                {chunkedCustomerList?.map((chunk, index) => (
                                  <div key={index} className=''>
                                    {chunk.map((el, innerIndex) => (
                                      <TableList
                                        key={innerIndex}
                                        id={el.id}
                                        onEdit={() => onEdit(el.id, el.name, el.tel, el.address, el.neighborhood)}
                                        onDelete={() => onDelete(el.name, innerIndex)}
                                        name={el.name}
                                        tel={el.tel}
                                        address={el.address}
                                        neighborhood={el.neighborhood}
                                        newOrder={()=> newOrder(el)}
                                      />
                                    ))}
                                  </div>
                                ))}
                              </Slider>
                            ):
                            (
                              <div>
                                {
                                  matchingClient?.length === 0 ? 
                                  (
                                    <>
                                      <div className="row justify-content-center">
                                        <div className="col-md-3">
                                          <button
                                            type='button'
                                            className="btn-apple rounded-2"
                                            onClick={handleCreateNewClient}
                                          >Crear este cliente</button>
                                        </div>
                                      </div>
                                      <div
                                        className='rounded-3 bg-b1 p-4 text-center text-white my-3'
                                      >
                                        No hay nombres o números de contacto que coincidan con tu criterio de búsqueda.
                                      </div>
                                    </>
                                  )
                                  :
                                  (
                                    matchingClient?.map((el, index) => (
                                      <TableList
                                        key={index}
                                        id={el?.id}
                                        onEdit={() => onEdit(el?.id, el?.name, el?.tel, el?.address, el?.neighborhood)}
                                        onDelete={() => onDelete(el?.name, index)}
                                        name={el?.name}
                                        tel={el?.tel}
                                        address={el?.address}
                                        neighborhood={el?.neighborhood}
                                        newOrder={()=> newOrder(el)}
                                      />
                                    ))
                                  )
                                }
                              </div>
                            )
                          }
                          {
                            filterClient?.length === 0 && (
                              <>    
                                <div className="mt-5">
                                  <div className="row justify-content-center">
                                    <div className="col-md-3 col-6">
                                      <button
                                        className={`btn-line-apple ${customerList?.length > countPagination ? '': 'disabled'}`}
                                        onClick={slidePrev}
                                      >{`< Anterior`}</button>
                                    </div>
                                    <div className="col-md-3 col-6">
                                      <button
                                        className={`btn-line-apple ${customerList?.length > countPagination ? '': 'disabled'}`}
                                        onClick={slideNext}
                                      >{`Siguiente >`}</button>
                                    </div> 
                                  </div>
                                </div>
                              </>
                            )
                          }
                      
                        </div>
                      )
                  }
                </div>
              </div>
            </section>
          </>
        ):
        <div className="p-4">
          <div className="row justify-content-center my-4">
            <div className="col-md-6">
              <InfoGreen
                bg={true}
                text='Debes activar el menú para realizar cualquier pedido desde tu administrador'
              />
            </div>
          </div>
        </div>
      }
      {stateLoading && <LoadingForm />}
      <ModalDeleteClient />
      <ModalCreateOrder />
    </>
  )
}

export default Customer



import React from 'react'
import Bold from './bold/Bold'

const Payments = () => {
  return (
    <section className="py-4">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <h4 className="text-center text-apple mb-4">Métodos de pago</h4>

            <Bold />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Payments

import React, { useState } from 'react'
import {signOut} from 'firebase/auth'
import { resetUserState, setUserId } from '../../../redux/reducer/admin'
import { resetMenuInfo } from '../../../redux/reducer/menu'
import { useDispatch, useSelector } from 'react-redux'
import { auth } from '../../../firebase/credentials'
import { Link } from 'react-router-dom'
import ModalReportMistake from '../../../components/ModalReportMistake'
import ModalChangePassword from '../../../components/ModalChangePassword'
import ModalTYCDashboard from '../../../components/ModalTYCDashboard'
import {useSwipeable } from 'react-swipeable'
import dismissStep from './change-password/fx/dismissStep'




const HeaderDash = () => {
  
  const [openMenu, setOpenMenu] = useState(false)
  const {phone, userID, name, step} = useSelector(state => state.log)
  const {country} = useSelector(state => state.menu)
  const dispatch = useDispatch()
  


  const closeSesion = ()=> {
    signOut(auth)
    dispatch(setUserId(null))
    dispatch(resetUserState())
    dispatch(resetMenuInfo())
  }


  const handleSwipeRight = useSwipeable({
    onSwipedRight: e => {
      setOpenMenu(false)
    }
  })

  const handleStep = async ()=> await dismissStep(userID,3)



  return (
    <>
      <header className='headerDashboard'>
        <div>
          <Link
            to={`/menu/${country}${phone}`}
            className={`orange-label ${step === 2 ? 'active': ''}`}
            target="_blank"
            rel="noreferrer"
            onClick={handleStep}
          >Ver menú digital</Link>
          <a 
            href="https://api.whatsapp.com/send?phone=-3573125599832&text=Hola,%20necesito%20ayuda%20con%20lo%20siguiente..."
            target="_blank"
            className='chatBtn'
            rel="noopener noreferrer"
          >¿Necesitas ayuda?</a>
        </div>
        <div>
          <p className="m-0 text-white pe-3">Hola, <span className='text-capitalize'>{name}</span></p>
          <div className="dropUser">
            <button
              className="btn"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              Mi usuario
            </button>
            <ul className="dropdown-menu">
              <button 
                type='button'
                data-bs-toggle="modal"
                data-bs-target="#reportMistake"
              >Reportar un problema</button>
              <button 
                type='button'
                data-bs-toggle="modal"
                data-bs-target="#modalChangePassword"
              >Cambiar mi contraseña</button>
              <a 
                href='https://api.whatsapp.com/send?phone=573125599832&text=Hola,%20necesito%20ampliar%20la%20informaci%C3%B3n%20acerca%20de%20...'
                target='_blank'
                rel="noopener noreferrer"
              >Comunicarme con un asesor</a>
              <a 
                href='https://fooderapp.co/terms'
                target='_blank'
                rel="noopener noreferrer"
              >Términos y condiciones</a>
              
              <button 
                type='button'
                onClick={closeSesion}
              >Cerrar Sesión</button>
            </ul>
          </div>
        </div>
        
      </header>
      <header className="headerMobile">
        <div>
          <Link
            to={`/menu/${country}${phone}`}
            target="_blank"
            rel="noopener noreferrer"
            className={`orange-label ${step === 2 ? 'active': ''}`}
            onClick={handleStep}
          >Ver menú</Link>
          <a 
            href="/"
            target="_blank"
            className='chatBtn'
            rel="noopener noreferrer"
          >Ayuda</a>
        </div>
        <button onClick={()=> setOpenMenu(!openMenu)}>
          <div></div>
          <div></div>
          <div></div>
        </button>
      </header>

      <nav
        className={`plegableMenu ${openMenu ? 'active': ''}`}
        {...handleSwipeRight}
      >
        <div className="overlay" onClick={()=> setOpenMenu(!openMenu)}/>
        <div className="content">
          <h6 className="text-end text-white mb-2">Hola, <span className='text-capitalize'>{name}</span></h6>
          <h5 className="text-end text-apple mb-4">¿Necesitas ayuda?</h5>
          <button 
            type='button'
            data-bs-toggle="modal"
            data-bs-target="#reportMistake"
            className='linkPane'
          >Reportar un problema</button>
          <button 
            type='button'
            data-bs-toggle="modal"
            data-bs-target="#modalChangePassword"
            className='linkPane'
          >Cambiar mi contraseña</button>
          <a 
            href='https://api.whatsapp.com/send?phone=573125599832&text=Hola,%20necesito%20ampliar%20la%20informaci%C3%B3n%20acerca%20de%20...'
            target='_blank'
            className='linkPane'
            rel="noopener noreferrer"
          >Comunicarme con un asesor</a>
          <button 
            type='button'
            data-bs-toggle="modal"
            data-bs-target="#modalTYCDashboard"
            className='linkPane'
          >Términos y condiciones</button>
          <button className="sesion justify-content-center p-4" onClick={closeSesion}>Cerrar sesión</button>
        </div>
      </nav>

      <ModalReportMistake />
      <ModalChangePassword />
      <ModalTYCDashboard />
    </>
  )
}

export default HeaderDash